<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Склады</div>
                <div class="page__desc">Список складов</div>
            </div>
            <div class="page__actions">
                <router-link
                    :to="{ name: 'StoreStoresCreate' }">
                    <button type="button" class="btn btn-success">Добавить</button>
                </router-link>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Адрес</th>
                        <th></th>
                    </tr>
                    <tr v-for="(store, index) in stores" v-bind:key="store.id">
                        <td>{{ store.address }}</td>
                        <td>
                            <router-link
                                    :to="{ name: 'StoreStoresLeftovers', params: {id: store.id } }"
                                    tag="input"
                                    type="button"
                                    class="btn btn-sm btn-outline-primary"
                                    value="Остатки"/>
                            <router-link
                                    :to="{ name: 'StoreStoresUpdate', params: {id: store.id } }"
                                    tag="input"
                                    type="button"
                                    class="btn btn-sm btn-warning"
                                    value="Изменить"/>
                            <input
                                    @click="deleteStore(index)"
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    value="Удалить">
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreStores',
        computed: {
            stores() {
                return this.$store.state.stores.stores
            }
        },
        methods: {
            ...mapActions([
                'storeStores',
                'storeStoresDelete'
            ]),
            async getStores() {
                await this.storeStores()
            },
            async deleteStore(index) {
                if (confirm('Вы уверены, что хотите удалить?')) {
                    let store = this.stores.splice(index, 1)[0];

                    await this.storeStoresDelete({
                        id: store.id
                    })
                }
            }
        },
        created() {
            this.getStores();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>